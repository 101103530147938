<template>
    <div :class="['plan', { [classNames]: true, [modalType]: true, active: isVisible, 'slide-out': !isVisible }]"
        :style="modalStyles" @click.self="handleClickOutside">
        <div class="plan-header" v-if="title">
            <div class="plan-breadcrumbs">
                <div class="plan-breadcrumbs-left">
                    <i class="iatse-icon-left-arrow close-plan-modal" @click="closeModal"></i>
                    <h3>{{ title }}</h3>
                </div>
                <div class="close-btn close-plan-modal" @click="closeModal"> <i class="iatse-icon-close"></i></div>
            </div>
        </div>
        <component :is="getModalContent(modalType)" @close="closeModal" @close-above="closeAbove" :fund="fund" :modalProps="modalProps" :index="index" />
    </div>
    <div v-if="modalType === 'success-modal'" class="modal-overlay success-modal-overlay"></div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    name: 'ModalComponent',
    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        modalType: {
            type: String,
            default: ''
        },
        classNames: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        fund: {
            type: String,
            default: null,
        },
        modalProps: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const modalStore = useModalStore();

        const closeModal = () => {
            emit('close', props.index);
        };

        const closeAbove = (index: number) => {
            emit('close-above', index);
        };

        const modalStyles = computed(() => {
            const offset = 290 + Number(props.index * 20);

            return {
                "--modal-width": `calc(100% - ${offset}px)`,
            };
        });

        const handleClickOutside = () => {
            modalStore.closeAbove(props.index);
        }

        return {
            modalStyles,
            closeModal,
            closeAbove,
            handleClickOutside,
            getModalContent: modalStore.getModalContent,
        };
    }
});
</script>