import { defineStore } from "pinia";
import AlertsComponent from "@/components/user/AlertsComponent.vue";
import ArchivedAlerts from "@/components/user/ArchivedAlerts.vue";
import AccountSettings from "@/components/user/AccountSettings.vue";
import ProfileComponent from "@/components/user/ProfileComponent.vue";
import PersonalInformation from "@/components/user/profile/PersonalInformation.vue";
import AddressInformation from "@/components/user/profile/AddressInformation.vue";
import ContactInformation from "@/components/user/profile/ContactInformation.vue";
import VacationHistory from "@/components/participant/vacation/VacationHistory.vue";
import CheckReplacement from "@/components/participant/vacation/CheckReplacement.vue";
import CheckDestination from "@/components/participant/vacation/CheckDestination.vue";
import AnnuityApplication from "@/components/participant/annuity/AnnuityApplication.vue";
import PensionApplication from "@/components/participant/pension/PensionApplication.vue";
import DependentsTable from "@/components/participant/dependents/DependentsTable.vue";
import AddDependent from "@/components/participant/dependents/AddDependent.vue";
import BeneficiariesTable from "@/components/participant/beneficiaries/BeneficiariesTable.vue";
import BeneficiariesCards from "@/components/participant/beneficiaries/BeneficiariesCards.vue";
import AddBeneficiary from "@/components/participant/beneficiaries/AddBeneficiary.vue";
import DistributeAllocation from "@/components/participant/beneficiaries/DistributeAllocation.vue";
import AssignFund from "@/components/participant/beneficiaries/AssignFund.vue";
import MissingContribution from "@/components/participant/MissingContribution.vue";
import ClaimsComponent from "@/components/participant/claims/ClaimsComponent.vue";
import ClaimSubmission from "@/components/participant/claims/ClaimSubmission.vue";
import ClaimHistoryResults from "@/components/participant/claims/ClaimHistoryResults.vue";
import ClaimHistorySearch from "@/components/participant/claims/ClaimHistorySearch.vue";
import CappCoPayment from "@/components/participant/coverage/CappCoPayment.vue";
import MakeCoverage from "@/components/participant/coverage/MakeCoverage.vue";
import SuccessModal from "@/components/cards/SuccessModal.vue";
import WorkHistoryView from "@/views/participant/WorkHistoryView.vue";
import DocumentsView from "@/views/participant/dependents/DocumentsView.vue";
import { ModalProps } from '@/interfaces/interfaces';
import { Component } from "vue";

interface Modal {
    type: string;
    title?: string | null;
    classes?: string | null;
    fund?: string | null;
    isVisible?: boolean;
    modalProps?: ModalProps;
}

type ComponentMap = {
    [key: string]: Component | null;
};

export const useModalStore = defineStore({
    id: "modal",
    state: () => ({
        modals: [] as Modal[],
    }),

    actions: {
        openModal(
            type: string,
            title?: string | null,
            classes?: string | null,
            fund?: string | null,
            modalProps?: ModalProps,
        ) {
            const existingModal = this.modals.findIndex(
                (modal) => modal.type === type
            );

            // console.log(existingModal, "@existing");

            if (existingModal !== -1) {
                this.closeAbove(existingModal);
            } else {
                this.modals.push({
                    type,
                    title,
                    classes,
                    isVisible: true,
                    fund,
                    modalProps,
                });
            }
        },
        closeModal(index: number) {
            const modal = this.modals[index];
            if (!modal) return;

            modal.isVisible = false;

            setTimeout(() => this.modals.splice(index, 1), 300);
        },
        closeAllModals() {
            this.modals.forEach((modal) => (modal.isVisible = false));
            setTimeout(() => (this.modals = []), 300);
        },
        closeAbove(index: number) {
            console.log(index, '@index')
            for (let i = this.modals.length - 1; i > index; i--) {
                this.modals[i].isVisible = false;

                setTimeout(() => this.modals.splice(i, 1), 300);
            }
        },
        handleSubmit() {
            const topModal = this.modals[this.modals.length - 1];

            if (
                topModal?.modalProps &&
                typeof topModal?.modalProps?.onConfirm === "function"
            ) {
                topModal.modalProps.onConfirm();
                this.closeModal(this.modals.length - 1);
            }
        },
        getModalContent(type: string): ComponentMap[keyof ComponentMap] | null {
            const componentsMap: ComponentMap = {
                alerts: AlertsComponent,
                profile: ProfileComponent,
                dependents: DependentsTable,
                beneficiaries: BeneficiariesTable,
                vacation: VacationHistory,
                claims: ClaimsComponent,
                "archived-alerts": ArchivedAlerts,
                "account-settings": AccountSettings,
                "update-personal-info": PersonalInformation,
                "update-address": AddressInformation,
                "update-contact-information": ContactInformation,
                "check-replacement": CheckReplacement,
                "check-destination": CheckDestination,
                "annuity-application": AnnuityApplication,
                "pension-application": PensionApplication,
                "work-history": WorkHistoryView,
                "add-dependent": AddDependent,
                "dependent-documents": DocumentsView,
                "add-beneficiary": AddBeneficiary,
                "assign-fund": AssignFund,
                "missing-contribution": MissingContribution,
                "claim-submission": ClaimSubmission,
                "claim-history-results": ClaimHistoryResults,
                "claim-search": ClaimHistorySearch,
                "make-coverage": MakeCoverage,
                "make-co-payment": CappCoPayment,
                "success-modal": SuccessModal,
            };

            return componentsMap[type] || null;
        },
    },
});
