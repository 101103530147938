<template>
    <div>
        <div class="impersonation-bar text-center" v-if="isImpersonating">
            <p>
                You are currently impersonating {{ impersonationName }}.
                <button class="btn btn-primary" @click="terminateImpersonation">Stop impersonating</button>
            </p>
        </div>

        <header v-if="isAuthenticated">
            <div class="header-wrapper">
                <h3>Hello, {{ isImpersonating ? impersonationName : userName }}!</h3>
                <div class="header-navigation">
                    <div class="header-notifications plan-modal" @click="openModal('alerts', 'Alerts', null)">
                        <p class="header-notifications"><i class="iatse-icon-Bell"></i>
                            <span v-if="unreadAlerts" class="notification-count"> {{ unreadAlerts }} </span>
                        </p>
                        <p>Notifications</p>
                    </div>
                    <div class="divider"></div>
                    <p @click="openModal('account-settings', 'Account Settings', 'plan-card-info')" class="plan-modal">
                        <i class="iatse-icon-Settings"></i>Account Settings
                    </p>
                    <div class="divider"></div>
                    <div class="plan-modal my-profile-wrapper">
                        <p class="plan-modal">
                            <i class="iatse-icon-user-rounded"></i> My Profile
                            <i class="iatse-icon-arrow-down"></i>
                        </p>
                        <div class="profile-dropdown-menu">
                            <p class="plan-modal" @click="handleProfileOpen">Profile Settings</p>
                            <a class="plan-modal" href="#" @click.prevent>Help & Support</a>
                            <a class="plan-modal" href="#" @click.prevent>Privacy Policy</a>
                            <!-- <p @click="openModal('archived-alerts', 'Archived Alerts', 'alerts')"
                                class="dropdown-item plan-modal">
                                Archive</p> -->
                            <p class="plan-modal" @click="performLogout">Log Out</p>
                        </div>
                    </div>
                </div>
                <div class="card-control" :class="{ 'plus': !visibleContent }" @click="toggleVisible">
                    <i class="plus iatse-icon-plus" v-if="!visibleContent"></i>
                    <span class="line" v-if="visibleContent"></span>
                </div>
            </div>
            <transition name="slide-fade">
                <div class="dashboard-details mt-45 card-toggle" v-if="visibleContent && !isSuperAdmin">
                    <div v-for="item in dashboardItems" :key="item.title" class="dashboard-detail-item pdl-0">
                        <InfoCard :iconClass="item.iconClass" :title="item.title" :detail="item.detail" />
                    </div>
                </div>
            </transition>
            <div class="divider"></div>
        </header>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useMembersStore } from '@/stores/members'
import { useRouter } from 'vue-router';
import InfoCard from './cards/InfoCard.vue';

export default defineComponent({
    name: 'HeaderComponent',
    components: {
        InfoCard,
    },
    emits: ['openModal'],
    setup(_, { emit }) {
        const authStore = useAuthStore();
        const router = useRouter();
        const isImpersonating = computed(() => authStore.isImpersonating);
        const isSuperAdmin = computed(() => authStore.isSuperAdmin);
        const isAuthenticated = computed(() => authStore.isAuthenticated);
        const impersonationName = computed(() => authStore.impersonationName);
        const userName = computed(() => authStore.userName);
        const dashboardItems = computed(() => authStore.dashboardItems);
        const unreadAlerts = computed(() => authStore.unreadAlerts);
        const dashboardRoute = computed(() => authStore.getDashboardRoute());
        const isProfileMenuOpen = ref(false);
        const visibleContent = ref(true);

        // Method for performing logout
        const performLogout = () => {
            useMembersStore().setList([]);
            authStore.logout();
            isProfileMenuOpen.value = false;
        };

        const toggleVisible = () => {
            visibleContent.value = !visibleContent.value;
        }

        const terminateImpersonation = async () => {
            try {
                // Use the store's action to start impersonation
                await authStore.stopImpersonation();
                router.push({ name: 'superadmin.dashboard' });
                // Optionally, handle any post-login logic, like redirecting the user
            } catch (error) {
                console.error("Impersonation error:", error);
            }
        };

        const handleProfileOpen = () => {
            if (!isSuperAdmin.value) {
                openModal('profile', 'My Profile', 'plan-profile plan-tables')
            }
        }

        const openModal = (type: string, title: string, classes: string | null) => {
            emit('openModal', type, title, classes);
        };

        return {
            isAuthenticated: isAuthenticated,
            isImpersonating: isImpersonating,
            impersonationName: impersonationName,
            isSuperAdmin,
            userName: userName,
            isProfileMenuOpen,
            unreadAlerts,
            dashboardRoute,
            dashboardItems,
            visibleContent,
            toggleVisible,
            performLogout,
            handleProfileOpen,
            terminateImpersonation,
            openModal
        };
    }
});
</script>
