<template>
    <div :class="['checkbox', className]">
        <div class="checkbox-input">
            <label v-if="showLabel && left" class="body-text" :for="label">{{ label }}</label>

            <input type="checkbox" :id="label" :name="name" :class="['links', className]" v-model="localValue"
                @change="handleChange" />

            <label v-if="showLabel && !left" class="body-text" :for="label">{{ label }}</label>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        left: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'text-group'
        }
    },
    setup(props, { emit }) {
        const localValue = ref(props.modelValue);

        const handleChange = () => {
            emit('update:modelValue', localValue.value);
        };

        watch(() => localValue.value, (newValue) => {
            localValue.value = newValue;
        });

        return {
            localValue,
            handleChange
        };
    }
});
</script>